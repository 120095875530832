@import '../../node_modules/bootstrap/scss/_functions';
@import 'theme/functions';

@import '../../node_modules/bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'user-variables';
@import 'theme/variables';
@import '../../node_modules/bootstrap/scss/variables';

//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// DataTables
table.dataTable tbody tr {
	border-bottom: 1px solid #ccc;
}

table.dataTable tbody tr:hover {
	background-color: #f0f0f0;
}

.dt-right-align {
	text-align: right;
}


// flatpickr
.fp-input {
	width: auto;
	min-width: 225px; /* Adjust this value based on your expected date format */
	padding: 0.5em;
	box-sizing: border-box;
}

// CALENDAR / SCHEDULE
//fullcalendar

.inline-badge {
	//position: absolute;
    font-size: 1em;
    border: 0.5px solid gray;
    border-radius: 50%;
    width: 1.5em;
    aspect-ratio: 1 / 1; /* Ensures width and height are always equal */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
}

.title-badge {
	position: absolute; /* Position the icon absolutely within the container */
	top: -13px; /* Move it to the top */
	right: 3px; /* Align it to the right */
	font-size: 12px; /* Adjust icon size */
	border: .5px solid gray;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	background-color: #eeeeee;
}

.evt-detail {
	overflow-y: auto;
}

.fc-event {
	background-color: #a6c0d3b6 !important;
	border-color: #1c3d22 !important;
}

.fc .fc-daygrid-event {
	border-radius: .375rem !important;
	margin-top: 0;
	margin-bottom: .25rem !important;
	padding: .25rem .25rem !important;
	font-size: .64rem;
	border: 1px solid var(--phoenix-border-color) !important;
}

.cal-add-items { //cba client, barn, animal
	max-height: 250px;
	overflow-y: auto;
}

// formatted text card
.text-card {
	white-space-collapse: preserve;
	text-wrap: wrap;
}

//chat stuff
.avatar-select {
	width: 75px;
	height: 75px;
}

.avatar-select.selected {
	border: 3px solid lightskyblue;
	border-radius: 50%;
}

.hover:hover {
	cursor: pointer;
	border: 2px solid green;
	border-radius: 50%;
}

.avatar-group-image {
	max-height: 25px;
}

//end chat stuff

#templateRow {
	display: none;
}


// Tabulator


.tabulator {
	border: none;
	background-color: transparent;
}
.tabulator .tabulator-header {
	background-color: transparent;
}
.tabulator .tabulator-header .tabulator-col {
	background-color: transparent;
}
.tabulator-col-title {
	background-color: transparent;
}

.tabulator-row {
	border: .5px solid #c8cbd2;
	border-left: none;
	border-right: none;
	border-top: none;
	.tabulator-cell {
		border: none;
	}
}

.tabulator-col {
    border: none !important;
}

.tabulator-row .tabulator-cell.tabulator-row-header {
	background: none;
	border: none;
	align-content: center;
}

// need this to see bootstrap dropdown menus 
.tabulator-tableholder {
	//overflow: visible !important;
}

.tabulator {
	//overflow: visible !important;
}

// need this to see bootstrap dropdown menus ...but it messes up the responsive part of the table so forget it
.tabulator-button-cell {
	//overflow: visible !important;
}

.tabulator-txt-wrap {
	white-space: normal !important;
    word-break: break-word;
}

//detail expand stuff
.tabulator-row .tabulator-responsive-collapse {
	border-top: #7a7a7a;
}

.tabulator-cell-img-container {
	height: 100%;
	width: auto;
	aspect-ratio: 1;
}

.tabulator-cell-img-container img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

// end tabulator

// echarts



// end echarts


// RANDOM and GENERAL

.modal-search-list {
	max-height: 240px;
	overflow-y: auto;
}

// tables in general

// use this to set the column width of a cell to width of contents...mostly for icon-only columns
.content-width-cell {
    width: 1%;
    white-space: nowrap;
}

// only border between rows
.table-row-divider tr:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

// for backgrounds in cards (usually) to display a disconnected but related data object
// not used for now...need a dark mode option
.bg-card-data-yellow {
	background-color: rgb(255, 250, 156);
}

.image-container {
	position: relative;
	display: flex;
	align-items: center;
  }

.badge-icon {
	position: absolute;
	min-width: 15px;
	//max-width: 15px;
	min-height: 15px;
	max-height: 15px;
	font-size: 10px;
	text-align: center;
	line-height: 15px;
	bottom: 7px; /* Adjust as needed */
	right: 0px; /* Adjust as needed */
	color: white; /* Color of the icon */
	background-color: red; /* Background color of the badge */
	border-radius: 50%; /* Makes the badge circular */
  }
  
.badge-count {
	position: absolute;
	min-width: 15px;
	min-height: 15px;
	max-height: 15px;
	line-height: 15px;
	text-align: center;
	bottom: 7px; /* Adjust as needed */
	right: 0px; /* Adjust as needed */
	font-size: 11px;
	//font-weight: bold;
	color: rgb(255, 255, 255);
}


.max-width-600 {
	max-width: 600px;
}

.right-corner-icon {
	position: absolute;
	top: 5px;
	z-index: 1;
}

.navbar-producer-header {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(11, 181, 19);
	color: white;
	width: 100%;
}

.navbar-basic-header {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: red;
	color: white;
	width: 100%;
}

.navbar-pro-header {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(0, 8, 255);
	color: white;
	width: 100%;
}

.hidden {
	display: none;
}

.feather {
    width: 16px;
    height: 16px;
}

tr.hoverable:hover {
	background-color: #d8e8f8;
}

.modal-wide {
	--phoenix-modal-width: 75%;
}

.modal-medium-wide {
	--phoenix-modal-width: 50%;
}

// Buttons / clickables / borders

//log category select
.dropdown-content {
	position: absolute;
	//top: 0; /* Place it below the button */
	right: 0;
	//width: 100%; /* Adjust width as needed */
	z-index: 1000; /* Ensure it's on top */
	//background-color: white; /* Dropdown background */
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

.dropdown-content-right {
	position: absolute;
	transition: none;
	//top: 0; /* Place it below the button */
	left: 0;
	//width: 100%; /* Adjust width as needed */
	z-index: 1000; /* Ensure it's on top */
	//background-color: white; /* Dropdown background */
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

// log icon
.circle-border {
	display: inline-block;
	width: 1.5em; /* Adjust size */
	height: 1.5em; /* Adjust size */
	border: 1px solid black; /* Adjust border color and thickness */
	border-radius: 50%;
	text-align: center;
	line-height: 1.5em;
	font-size: 1.5em; /* Same as height for vertical centering */

}
  
.circle-border i {
	font-size: .25em; /* Adjust icon size */
	color: rgb(182, 37, 37); /* Adjust icon color */
}

.label-link {
	color: #007bff;
}
.label-link:hover {
	cursor: pointer;
	text-decoration: underline;
}

.btn-click-only {
	display: flex;
    align-items: center;

	background: none;
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.btn-click-only:focus {
	outline: none;
}

.btn-link {
	background: none;
	border: none;
	color: #007bff;
	text-decoration: none;
	cursor: pointer;
	padding: 0;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.search-result:hover {
	cursor: pointer;
}

.sm-scroll-table {
	max-height: 200px; /* Set the maximum height for the table */
	overflow-y: auto; /* Add vertical scrollbar when content exceeds height */
}

// inputs

.no-decor {
	border: none;
	outline: none;
}

.underline-select {
	border: none;
	outline: none;
	border-bottom: 1px solid #007bff;
}

.date-input {
	max-width: 120px;
	text-align: center !important;
}

.underline-input {
	width: 100%;
	text-align: left;
	border: none;
	border-bottom: 1px solid #007bff; // Adjust the color and thickness as needed
	outline: none; // Remove default input outline
	transition: border-bottom 0.3s ease; // Optional: add a transition effect
	display: inline-block;
  
	&:focus {
	  border-bottom: 1px solid #ff4500; // Adjust the color for focused state
	}
}

.value-input {
	width: 8ch; // Set the desired width
	text-align: center;
	border: none;
	border-bottom: 1px solid #007bff; // Adjust the color and thickness as needed
	outline: none; // Remove default input outline
	transition: border-bottom 0.3s ease; // Optional: add a transition effect
	display: inline-block;
  
	&:focus {
	  border-bottom: 1px solid #ff4500; // Adjust the color for focused state
	}
}

// image gallery
.flex-container {
	display: flex;
	gap: 2px;
	padding: 0;
	margin: 0;
	//justify-content: flex-start;
    flex-direction: row; /* Align items in a row */
    flex-wrap: wrap; /* Allow wrapping if needed */
	//height: 600px;
	//align-items: center;
    //overflow: hidden; /* Hide any overflow */
}

.flex-item {
	flex: 1 1 auto; /* Allow items to grow and shrink */
	display: flex;
	align-items: center;
	//justify-content: center;
	width: auto;
	max-height: 150px;
}

.flex-item img {
	max-height: 150px;
    height: auto; /* Make the image fill the height of its container */
    width: auto; /* Maintain aspect ratio by adjusting width */
    object-fit: contain; /* Ensure the image covers the container without stretching */
}


// end image gallery

// overrides

// fix main search box so it shrinks on smaller screens


.img-thumbnail {
	padding: .1em;
}

// for content tag
/* For mobile devices (up to 576px) */
@media (max-width: 576px) {
    .content {
		min-height: 100vh;
		padding: calc(var(--phoenix-navbar-top-height) + 1rem) 1rem 6.375rem 1rem;
		padding-bottom: 6rem;
    }
	.nav-link {
		font-size: 1.25rem;
		align-items: center;
		align-content: center;
		text-align: center;
		//margin: 5px;
		padding: 20px;
		padding-left: 30px;
		border-bottom: 1px;
	}
	.navbar-vertical-label {
		font-size: 1.25em !important;
	}
	.footer {
		min-height: 100vh;
		padding: calc(var(--phoenix-navbar-top-height) + 1.75rem) 1rem 6.375rem 1rem;
		padding-bottom: 6rem;
    }
	.search-box {
		width: auto;
	}
}

@media (min-width: 576px) {
    .search-box {
		width: 20rem;
	}

	.table-max-300 {
		max-width: 300px;
	}
}

.text-monospace {
	//font-family: "Courier New", Courier, monospace;
	font-family: 'Fira Code', monospace;
}

.dropdown-profile {
	min-width: 16rem;
}

.card-header {
	padding-top: 1em;
	padding-bottom: 1em;
}

.ui-timepicker-container {
	border-radius: 5px;
}

.spinner-border-sm {
    --phoenix-spinner-width: .75rem;
    --phoenix-spinner-height: .75rem;
    --phoenix-spinner-border-width: 0.2em;
}

.timepicker {
	text-align: right;
	//max-width: 75px;
}

// glightbox
.gedit {
	bottom: 20px;
	right: 20px;
	width: 35px;
	height: 35px;
	position: absolute;
	opacity: .7;
	background-color: rgba(0, 0, 0, 0);
	color: #ccc;
	z-index: 99999;
    cursor: pointer;
    border: none;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

// View Animal
// general

.textarea-md {
	width: 100%;
	min-height: 300px;
	border-color: #cbd0dd;
	border-radius: 5px;
}

// small
@media (max-width: 576px) {
	.animal-profile-pic {
		max-width: 100%;
		max-height: 300px;
	}
}
// large
@media (min-width: 576px) {
	.animal-profile-pic {
		max-width: 100%;
		max-height: 150px;
	}
}

//END VIEW ANIMAL

// HOME/DASHBOARD Layout
.navbar-vertical-content {
	//position: relative;
	//z-index: 5000;
}
.dashboard-container { //set to content to 
	max-height: calc(100vh - var(--phoenix-navbar-top-height));
	overflow: hidden;
}

.no-scroll-row {
	overflow: hidden;
	height: 100%;
}
.static-col {
	position: sticky;
	top: 0;
	overflow: hidden;
}
// sidebar for small screem
@media (max-width: 576px) {
	.side-button {
		border: solid;
		border-color: rgb(232, 234, 239);
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		position: fixed;
		right: -5px;
		/* top | right | bottom | left */
		padding: 15px 15px 15px 20px;
		bottom: 5em;
		z-index: 200;
		background-color: rgb(245, 247, 250);
		color: rgb(71, 115, 246);
		transition: background-color 0.3s; 
	}
	.side-button:active {
		transform: scale(0.95); /* Simulates a "pressed" effect by shrinking the button */
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow when pressed */
		background-color: #245475; /* Darkens the button color when pressed */
		color: white;
	}
	
	.static-col {
		position: fixed;
		top: var(--phoenix-navbar-top-height);
		padding-top: 1em;
		right: -100%;
		height: 100%;
		width: 100%;
		background-color: var(--phoenix-body-bg);
		transition: right 0.3s ease; 
		z-index: 100; 
	}
	.static-col.show {
		right: 0;
	}
}

.scrollable-col {
	height: 100vh;
	overflow-y: auto;
}

// LAYOUTS for SCREEN SIZES ETC

@media (max-width: 576px) {
    html {
        font-size: 20px;
    }
	.content {
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.tabulator-content-width {
		width: calc(100% + 2rem);
		margin-left: -1rem;
		margin-right: -1rem;
	}
	.tabulator-content-width-sm {
		width: calc(100% + 2rem);
		margin-left: -1rem;
		margin-right: -1rem;
	}
	.tabulator-row {
		min-height: 50px !important;
	}
	.tabulator-cell {
		height: 90% !important; /* Adjust this value to match row height */
	}
}

@media (min-width: 576px) {
    .column-text {
        font-size: 14px;
    }
	.tabulator-content-width {
		width: calc(100% + 3rem);
		margin-left: -1.5rem;
		margin-right: -1.5rem;
		border: 0;
	}
}

@media (min-width: 992px) {
	.tabulator-content-width {
		width: calc(100% + 5rem);
		margin-left: -2.5rem;
		margin-right: -2.5rem;
	}
}

//SWIPER / FEED and POST stuff

.post-profile-img {
	max-width: 40px;
	padding: .1rem;
}
.post-display-name {
	line-height: normal;
}
.post-profile-quote {
	line-height: .5em
}

.post-text {
	//flex-grow: 1;
	align-content: center;
}

/* For mobile devices  */
@media (max-width: 576px) {
    .swiper {
		width: 100%;
		max-height: 475px;
	}
	.post-pic {
		object-fit: contain;
		//padding: .25rem;
		//background-color: var(--phoenix-emphasis-bg);
		border: var(--phoenix-border-width) solid var(--phoenix-border-color);
		border-radius: var(--phoenix-border-radius);
		max-height: 70%;
		max-width: 100%;
		align-self: center;
	}
}
/* For Desktop */
@media (min-width: 575px) {
    .swiper {
		width: 100%;
		max-height: 700px;
	}
	.post-pic {
		object-fit: contain;
		//padding: .25rem;
		//background-color: var(--phoenix-emphasis-bg);
		border: var(--phoenix-border-width) solid var(--phoenix-border-color);
		//border-radius: var(--phoenix-border-radius);
		max-height: 500px;
		width: fit-content;
		max-width: 100%;
	}
	.post-pic-mini {
		object-fit: contain;
		//padding: .25rem;
		//background-color: var(--phoenix-emphasis-bg);
		border: var(--phoenix-border-width) solid var(--phoenix-border-color);
		//border-radius: var(--phoenix-border-radius);
		max-height: calc(100% - 1rem);
		width: fit-content;
		max-width: 100%;
	}
}

.swiper-slide {
	display: flex;
	flex-direction: column; /* Layout items vertically */
	justify-content: center; /* Ensure image and text have their space */
	align-items: center; /* Center items horizontally */
	height: 100%; /* Make sure it takes full height */
	overflow: hidden;
}

.swiper-pagination {
    position: absolute;
    right: 10px; /* Adjust the positioning */
    top: 50%;
    transform: translateY(-50%);
}

.swiper-pagination-bullet {
    background: #000; /* Custom bullet color */
    width: 12px;
    height: 12px;
    margin: 5px 0;
}

.swiper-pagination-bullet-active {
    background: #007bff; /* Active bullet color */
}

// Feed PUG

.feed-item {
	background-color: var(--phoenix-modal-bg);
}

// mobile
@media (max-width: 576px) {
    .post-mini-container {
		display: flex;
		flex-direction: column; /* Layout items vertically */
		justify-content: center; /* Ensure image and text have their space */
		align-items: center; /* Center items horizontally */
		height: 100%; /* Make sure it takes full height */
		overflow: hidden;

		//min-height: 150px;
		//width: 125px;
		aspect-ratio: 4 / 5;
		height: 150px;
		//padding-bottom: 125%;
		margin: .25rem;
		padding: .25rem;
		background-color: var(--phoenix-emphasis-bg);
		border: var(--phoenix-border-width) solid var(--phoenix-border-color);
		border-radius: var(--phoenix-border-radius);
	}
	.post-pic-mini {
		object-fit: contain;
		border: var(--phoenix-border-width) solid var(--phoenix-border-color);
		max-height: calc(100% - 1rem);
		width: fit-content;
		max-width: 100%;
	}
	.feed-footer {
		font-size: large;
	}
	.commenter-pic img {
		width: 40px;
		height: 40px;

	}
}
// desktop
@media (min-width: 576px) {
	.view-post {
		position: relative;
		-webkit-box-flex: 1;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		//padding: var(--phoenix-modal-padding);
		//content
		position: relative;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
		//color: var(--phoenix-modal-color);
		pointer-events: auto;
		//
		//background-clip: padding-box;
		//border: var(--phoenix-modal-border-width) solid var(--phoenix-modal-border-color);
		border-radius: 5px;
		//-webkit-box-shadow: var(--phoenix-modal-box-shadow);
		//box-shadow: var(--phoenix-modal-box-shadow);
		outline: 0;
	}

    .post-mini-container {
		display: flex;
		flex-direction: column; /* Layout items vertically */
		justify-content: center; /* Ensure image and text have their space */
		align-items: center; /* Center items horizontally */
		height: 100%; /* Make sure it takes full height */
		overflow: hidden;

		//min-height: 150px;
		//width: 125px;
		aspect-ratio: 4 / 5;
		height: 150px;
		//padding-bottom: 125%;
		margin: .25rem;
		padding: .25rem;
		background-color: var(--phoenix-emphasis-bg);
		border: var(--phoenix-border-width) solid var(--phoenix-border-color);
		border-radius: var(--phoenix-border-radius);
	}
	.commenter-pic img {
		max-height: 30px;
	}
	
}

.comment-loading-status {
	opacity: 0; /* Initially hidden */
    transition: opacity 0.5s ease-in-out;
}
.comment-loading-status.show {
	opacity: 1;
}

.new-comment-profile-pic img {
	max-height: 50px;
}

.post-new-comment {
	resize: vertical;
	overflow: hidden;
}
.send-comment {
	align-self: flex-start;
	height: auto;
}

.comment-display-name {
	font-size: .80em;
	font-weight: 700;
}
.comment-date-time {
	font-size: .75em;
}

.post-container-nopic {
	min-height: 200px;
}

// keep image a circle when viewing pub profile modal
.avatar img{
	width: 100%;
	height: auto;
}

// animalProfile
.comments {
	max-height: 100px;
	overflow: auto;
}


// INVENTORY
.item-pic {
	max-width: 150px;
}

// TASKS
.task-list {
	min-width: 300px;
}

.html2pdf {
	width: 900px;
	display: block;
}

.ql-toolbar {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.ql-container {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

// REPORT DESIGNER
.rpt-designer-page {
	height: calc(100vh - 70px);
}
.selected-group-item {
	background-color: #cf8487;
}
.selected-group {
	background-color: #8bc7e2;
}

//BREEDING RECORDS
.breeding-record:hover {
	background-color: rgb(247, 247, 247);
}
.palp-table {
	max-height: 150px;
	overflow-y: auto;
}
.palp-table table th {
	background-color: #f4f4f4;
	position: sticky; /* Makes the header fixed */
	top: 0; /* Sticks the header to the top of the container */
	z-index: 1; 
}

// TRAINING

.training-list {
	max-height: 400px;
	overflow-y: auto;
}

.training-record:hover {
	background-color: rgb(227, 227, 227);
}

.session-record {
	transition: background-color 0.2s ease;
}

.session-record:hover {
	background-color: rgb(227, 227, 227);
}

.session-record:active {
	background-color: rgb(193, 214, 255);
}

// VIDEO
.video-container {
	width: 100%; /* Adjust as needed */
	aspect-ratio: 16 / 9; /* Maintains a 16:9 ratio */
	overflow: hidden;
}

.video-container video {
	width: 100%;
	height: 100%;
	object-fit: cover; /* Maintain aspect ratio without cropping */
	border-radius: inherit; 
}

// small
@media (max-width: 576px) {
	.animal-profile-pic-training {
		max-width: 100%;
		max-height: 240px;
	}
}
// large
@media (min-width: 576px) {
	.animal-profile-pic-training {
		max-width: 100%;
		max-height: 100px;
	}
}

// UTIL
@media print {
	.progress {
		display: block !important; /* Ensure it appears */
		background-color: #e9ecef; /* Match the default background */
	  }
	  .progress-bar {
		display: block !important; /* Ensure visibility */
		background-color: #007bff !important; /* Default progress bar color */
	  }
}


// STRIPE

.App-Background {
	border: 1px;
	border-radius: 2px;
}